import $myApi from '@/gavnoCode/ApiHandler';
// если один из типов aside true, то он будет показан вместо по умолчанию
// name:'showAside',отвечает за показ самого контейнера aside
// внимание гавнокод в действии, если showAside false то aside показывается, если true то прячем =)
// в компоненнте app вычесляются класс для html тега
// в компоненте aside переключается шаблон
const defaultAsideList = () => {
  return[
    {
      name: 'showAside',
      status: false,
    },
    {
      name:'confirmRequest',
      status:false,
    },
    {
      name:'agentScript',
      status:false,
      ScriptValue:[],
    },
    {
      name:'pdfInstructions',
      status:false
    },
    {
      name: 'photoPassport',
      status: false
    },
    {
      name: 'importExcel',
      status: false
    },
    {
      name: 'ccScripts',
      status: false
    },
  ];
};

const getters = {
  getScripts: (state) =>  {
    return state.operatorScripts.list.filter((item) => {
      return item.id == state.operatorScripts.activeAgentScriptID;
    });
  },
  indexShowAside: (state) => (name) => {
    return state.asideState.asideList.findIndex((item) => {
      return item.name == name;
    });
  },
};


//asideOff список name из router.js при отображении который aside выключается
const state = {
  asideState: {
    asideList: defaultAsideList(),
    defaultView: true,
    asideOff: [
        // 'debtReference',
        'Статистика',
        'Реферальные ссылки',
        'Судебное взыскание',
        'Листы сбора PDL',
        'Контроль оригиналов',
      'Заявки Альфа в работе',
      'Cписок заявок Альфа, кредитная карта',
      'Cписок заявок Альфа, дебетовая карта',
      'Платежи',
      'Заявки',
      'Договора',
      'Организации',
      'Список менеджер оповещен',
      'Карта участков',
      'Отчет по заявкам',
      'Сотрудники',
      'Листы сбора',
      'Единое окно',
      'Поиск информации по карте Банка',
      'PDL',
      'Телефонное взыскание',
      'Телефонное взыскание Новосибирск',
      'Верификация заявок PDL',
      'Рабочее окно',
      'Инструкции',
      'Мотивация отчеты',
      'Видео пулл, новые заявки'
    ],
  },
  operatorScripts:{
    activeAgentScriptID:100,
    list:[],
  },
};

const actions = {
  setActiveAgentScriptID:({commit,dispatch},newValue)=>{
    let dbId = parseInt(newValue, 10) + 100;
    commit('setActiveAgentScriptID',dbId);
    dispatch('loadScriptById',state.operatorScripts.activeAgentScriptID);
  },
  loadScriptById: ({commit}, scriptId) => {
    return new Promise((resolve, reject) => {
      if (state.operatorScripts.list.filter((item) => {
        return item.id == scriptId
      }).length > 0){
        resolve();
      } else {
        $myApi.singleRequest({
          action: 'loadAgentScript',
          type:'VideoDemand',
          scriptId: scriptId,
        }).then((res) => {
          commit('addScript', {
            id: scriptId,
            values: res
          });
          resolve();
        }).catch((res) => {
          console.log(res);
          reject('Ошибка loadScriptById');
        });
      }
    });
  },
  cdDefaultView: ({commit}, value) => {
    commit('setAsideState',{
      target:'defaultView',
      value: value,
    });
  },
  cdCurrentPageAsideView: ({dispatch, commit, getters}, routingTarget) => {
    if (routingTarget.name != undefined) {
      //todo както без выебонов, переделать отображение контейнера aside

      dispatch('cdDefaultView', false);
      //выключаем весь контент aside, но из-за непродуманности показываем контейнер aside
      // , если showAside false то aside показывается
      commit('offAsideList');

      // проверяем на текущей странице показывать ли aside, если нашли в списке, вырубаем aside
      // и заодно проверяем текущий статус aside, если он спрятан то не стоит его прятать

      if (state.asideState.asideOff.findIndex((item) => {
          return item == routingTarget.name
        }) >= 0
        && state.asideState.asideList[getters.indexShowAside('showAside')].status == false
      ) {
        commit('toggleAsideWrap', {
          index: getters.indexShowAside('showAside'),
          value: true
        })
      } else if (state.asideState.asideList[getters.indexShowAside('showAside')].status == true) {
        commit('toggleAsideWrap', {
          index: getters.indexShowAside('showAside'),
          value: false
        });
      }

      //включаем особый контент aside для уникальных разделов
      switch (routingTarget.name) {
        case 'iu':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
        case 'topUpApplication':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
        case 'dealPayBook':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
          case 'OWDPrintNoticeOfDelay':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
          case 'Справка об отсутствии задолженности':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
            break;
          case 'Справка об отсутствии задолженности ':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
          case 'dealAct':
          commit('setAsideList',{
            target:'pdfInstructions',
            value: true,
          });
          break;
          case 'dealPaySchedule':
            commit('setAsideList',{
              target:'pdfInstructions',
              value: true,
            });
            break;
        case 'Заявка':
          commit('setAsideList',{
            target:'confirmRequest',
            value: true,
          });
          break;
        case 'Рабочее окно':
          dispatch('loadScriptById',state.operatorScripts.activeAgentScriptID);
          commit('setAsideList',{
            target:'agentScript',
            value: true,
          });
          break;
        case 'Детальный просмотр заявки PDL':
          commit('setAsideList',{
            target:'photoPassport',
            value: true,
          });
        break;
        case 'Загрузка реестра':
          commit('setAsideList',{
            target:'importExcel',
            value: true,
          });
        break;
        case 'Реестр обзвона':
          commit('setAsideList',{
            target:'ccScripts',
            value: true,
          });
          break;
        default:
          commit('setAsideState',{
            target:'defaultView',
            value: true,
          });
          break;
      }
    }
  }
};

const mutations = {
  toggleAsideWrap: (state, valueObj) => {
    state.asideState.asideList[0].status = valueObj.value;
    state.asideState.asideList[0].status = true;
  },
  setAsideState: (state, obj) => {
    state.asideState[obj.target] = obj.value
  },
  setAsideList:(state,obj)=>{
    let index = state.asideState.asideList.findIndex((item)=>{
      return item.name == obj.target
    });
    if (index >= 0) {
      state.asideState.asideList[index].status = obj.value;
    } else {
      state.asideState.asideList.push({
        name: obj.target,
        status: obj.value
      });
    }
  },
  offAsideList:(state)=>{
    for(let i=0 ; i<state.asideState.asideList.length; i+=1){
      state.asideState.asideList[i].status = false;
    }
  },
  addScript: (state, newVal) => {
    state.operatorScripts.list.push(newVal);
  },
  updateScript: (state, newVal) => {
    let index = state.operatorScripts.list.findIndex((item) => {
      return item.id == newVal.id
    });
    state.operatorScripts.list[index].value = newVal.value;
  },
  setActiveAgentScriptID:(state,newVal)=>{
    state.operatorScripts.activeAgentScriptID = newVal;
  }
};



export default {
  state,
  mutations,
  getters,
  actions
}
